<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <bootstrap-alert />
        <div class="card">
          <div class="card-header card-header-primary card-header-icon">
            <div class="card-icon">
              <i class="material-icons">remove_red_eye</i>
            </div>
            <h4 class="card-title">
              <strong>{{ $t("data.herd.title_singular") }}</strong>
            </h4>
          </div>
          <div class="card-body">
            <back-button></back-button>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-12">
                <div class="table-overlay" v-show="herdLoading">
                  <div class="table-overlay-container">
                    <material-spinner></material-spinner>
                    <span>{{ $t('global.loading') }}</span>
                  </div>
                </div>
                <div class="table-responsive">
                  <table class="table table-hover">
                    <tbody>
                      <tr></tr>
                      <tr>
                        <th scope="row" class="text-primary">
                          {{ $t("data.herd.fields.id") }}
                        </th>
                        <td>
                          {{ entry.id }}
                        </td>
                      </tr>
                      <tr>
                        <th scope="row" class="text-primary">
                          {{ $t("data.herd.fields.name") }}
                        </th>
                        <td>
                          {{ entry.name }}
                        </td>
                      </tr>
                      <tr>
                        <th scope="row" class="text-primary">
                          {{ $t("data.herd.fields.created_at") }}
                        </th>
                        <td>
                          {{ entry.created_at }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <assets-index></assets-index>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import BootstrapAlert from '../../../components/BootstrapAlert.vue';
import AssetsIndex from '../Asset/Index.vue'

export default {
  components: {
    AssetsIndex,
    BootstrapAlert,
  },
  beforeDestroy() {
    this.resetState;
  },
  computed: {
    ...mapGetters("HerdsSingle", ["entry", "herdLoading"])
  },
  watch: {
    "$route.params.id": {
      immediate: true,
      handler() {
        this.resetState;
        this.fetchShowData(this.$route.params.id);
      }
    }
  },
  methods: {
    ...mapActions("HerdsSingle", ["fetchShowData", "resetState"])
  }
};
</script>